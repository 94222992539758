import 'react-universal-hooks'
import React from 'react'
import Layout from '../../components/Layout'
import Amplify from 'aws-amplify'
import apiConfig from '../../utils/apiConfig'
import AccountCreatePage from '../../components/accounts/pages/AccountCreatePage'
import { GlobalStateProvider } from '../../store'

Amplify.configure(apiConfig)

export default class O365Callback extends React.Component {
  render = () => (
    <GlobalStateProvider>
      {/* <Layout {...this.props}> */}
        <AccountCreatePage {...this.props} />
      {/* </Layout> */}
    </GlobalStateProvider>
  )
}
